import React from 'react'
import GetAvailability from '../../components/Host/GetAvailability'

function Availability() {
  return (
        <GetAvailability />
  )
}

export default Availability
