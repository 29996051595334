import React from "react";
import GetPricing from '../../components/Host/GetPricing'

function Pricing() {
  return (
      <GetPricing />
  );
}

export default Pricing;
