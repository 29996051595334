import React from 'react'
import Review from '../components/Review/Review'

function WriteAReview() {
  return (
    <div>
        <Review />
    </div>
  )
}

export default WriteAReview